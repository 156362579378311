=<template>
  <div class="feeds-countries">
    <ul class="list-unstyled">
      <template v-if="mobilemenu.country != 'undefined' && !display">
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 3795032" :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}} ({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country"  >
          <li v-if="cat.id == 232" :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 225"  :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 236"  :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country"  >
          <li v-if="cat.id == 237" :key="`ct${cat.id}`" >
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 241" :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 6" :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country" >
          <li v-if="cat.id == 2"  :key="`ct${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }}
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`leaguedd${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
        <template v-for="cat in mobilemenu.country"  >
          <li v-if="cat.id != 3795032 && cat.id != 236 && cat.id != 237 && cat.id != 241 && cat.id != 232 && cat.id != 225 && cat.id != 2 && cat.id != 6" :key="`ctt${cat.id}`">
            <a :href="`#ct${cat.id}`" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" v-if="checkEmptyLeagues(cat)">
              <span :class="'flag-icon flag-icon-' + iso(cat.name).code.toLowerCase() +  ' flag-icon-squared'" @click="selectLeagues(cat.id)"></span>{{'\xa0'}}{{'\xa0'}}
                <template v-if="$i18n.locale == 'en'">
                  {{ iso(cat.name).name }} 
                </template> 
                <template v-if="$i18n.locale == 'fr'">
                  {{ iso(cat.name).fr_name }}
                </template>            
            </a>
            <ul :id="`ct${cat.id}`" class="collapse list-unstyled tier-two">
              <div v-frag v-for="league in cat.leagues" :key="`league${league.id}`">
                <li>
                    <a  @click="getLeagueGames(league.id,mobilemenu.id,league.name,league.french)"><i class="icofont-rounded-double-right"></i> {{ league.name }} {{'\xa0'}}{{'\xa0'}}({{ league.count }})</a>
                </li>
              </div>
            </ul>
          </li>
        </template>
      </template>
      <template v-if="display">
        <li v-for="cat in leagues.leagues" :key="`fd4${cat.id}`">
          <span 
           :class="'flag-icon flag-icon-' + iso(leagues.name).code.toLowerCase() +  ' flag-icon-squared'"
           >
          </span>
          {{'\xa0'}}{{'\xa0'}}
          <span @click="getLeagueGames(cat.id,mobilemenu.id,cat.name,cat.french)">{{ cat.name }}</span>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import { getCountryISO2 } from "@/utils/utility";

export default {
  props:['sport_id'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      load: false,
      display:false,
      leagues: null,
    }
  },
  computed: {
    ...mapGetters({
        menu: 'menu',
        top5: 'top5',
        markets: 'markets',
        icons:'sport_sidebar',
        
        
    }),
    mobilemenu() {
      return this.menu.find( x => x.id == this.sport_id)
    },
  },
  mounted() {
    this.$store.dispatch("fetch_sidebar_menu");
    // this.$store.dispatch("fetch_top_menu");
  },
  methods: {
    iso: function (code) {
      const res = getCountryISO2(code,'sidebar')
      if(res) {
          return res
      } else {
          return {
              "code": "FM",
              "code3": "DEF",
              "fr_name": code,
              "name": code,
              "number": "248"
          }
      }
    },
    getLeagueGames: function(id,sport,en_name) {  
        let url = "/sport/" + sport + "/league/" + id;
        let name = ( this.$i18n.locale == 'en') ? en_name : en_name;
        localStorage.setItem('league', JSON.stringify(name));
        this.$emit('changeType','feeds')
        
        if (this.$route.path != url) {          
            this.$router.push( url ) 
        }
    },
    getUpcomingGames: function (id) {  
        let url = "/sport/" + id
        if (this.$route.path != url ) {
            this.$router.push(url)
        }
    },
    checkEmptyLeagues: function(cat) {
        let chk = cat.leagues.some( val => val.count >= 1 )
        console.log(chk);
        return 22
    },
    checkLeaguesLength: function (cat) {
        let chk = cat.leagues.filter( val => val.count >= 1 ).length
        console.log(chk);
        return 22
    },
    selectLeagues(countryId) {
      this.display = true;
      let country = (this.mobilemenu) ? this.mobilemenu.country.find( x => x.id == countryId) : []
      this.leagues =  country;
    }
  }
}
</script>
<style scoped>
ul ul a {
    font-size: 0.9em !important;
    padding-left: 5px !important;
    background: var(--bg-dark-medium);
    color: #fff;
}
</style>