<template>
  <div class="container-fluid feeds">
    <div class="col-sm-12 feeds">
      <div class="div-flex">
        <the-sidebar class="hidden-sm hidden-xs"/>
        <div class="feed">
          <div class="contain-overflow">
            <div class="quick-links top">
              <ul class="list-inline">
                <li :class="{ 'text-orange' : sport_check == 'featured' }" @click="getFeaturedGames()">{{ $t('feeds.featured-games') }}</li>
                <li :class="{ 'text-orange' : sport_check == 'today' }" @click="getTodayGames(markets.sportId)">{{ $t('feeds.today') }}</li>
                <li :class="{ 'text-orange' : sport_check == 'upcoming' }" @click="getUpcomingGames(markets.sportId)">{{ $t('feeds.upcoming') }}</li>
                <li :class="{ 'text-orange' : sport_check == 'countries' }" @click="changeType('notfeeds')">{{ $t('feeds.countries') }}</li>
              </ul>             
            </div>
            <div class="quick-filters" v-if="!load">
              <span @click="toggleFilters">
                <i class="icofont-gear"></i> {{ $t('feeds.filters') }} <i class="icofont-simple-down"></i>
              </span>
              <ul class="list-inline" v-if="(markets.markets != undefined)">
                <li @click="toggleModal"  v-if="$i18n.locale == 'en'">{{ markets.markets.find( x => x.key == marketId).name}} <i class="icofont-simple-down"></i></li>
                <li @click="toggleModal"  v-if="$i18n.locale == 'fr'">{{ markets.markets.find( x => x.key == marketId).fr_name}} <i class="icofont-simple-down"></i></li>
              </ul>
            </div>
            <the-carousel :slides="slides"/>
            <div class="quick-filters btom" v-if="!load">
              <ul class="list-inline">
                <li v-if="markets.sportId != 10">
                  <span v-if="$i18n.locale == 'en'"><i class="icofont-close" @click="clearSport()"></i> {{ markets.en_name }}</span> 
                  <span v-if="$i18n.locale == 'fr'"><i class="icofont-close" @click="clearSport"></i> {{ markets.fr_name }}</span> 
                </li>
                <li v-if="(markets.markets != undefined)">
                  <span v-if="$i18n.locale == 'en'"><i class="icofont-close" @click="clearMarket"></i> {{ markets.markets.find( x => x.key == marketId).name }}</span> 
                  <span v-if="$i18n.locale == 'fr'"><i class="icofont-close" @click="clearMarket"></i> {{ markets.markets.find( x => x.key == marketId).fr_name }}</span> 
                </li>
              </ul>
            </div>
            <modal @close="toggleModal" @selectMarket="selectMarket" v-if="show && showType == 'markets'">
              <span class="title">{{ $t('feeds.markets')}}</span>
              <ul class="list-unstyled">
                <li v-for="(mar) in markets.markets" :key="mar.key" @click="selectMarket(mar.key)">
                  <span v-if="$i18n.locale == 'en'" >{{ mar.name }} <i class="icofont-tick-mark text-orange" v-if="mar.key == marketId"></i></span>
                  <span v-if="$i18n.locale == 'fr'" @click="selectMarket(mar.id)">{{ ($i18n.locale == 'en') ? mar.name : mar.fr_name }} <i class="icofont-tick-mark text-orange" v-if="mar.key == marketId"></i></span>
                </li>
              </ul>
            </modal>
            <modal @close="toggleModal" @selectType="selectType" v-if="show && showType == 'filters'">
              <ul class="list-unstyled">
                <li @click="selectType('featured','')">{{ $t('feeds.featured-games') }}</li>
                <li @click="selectType('today',markets.sportId)">{{ $t('feeds.today') }}</li>
                <li v-for=" x in 6" :key="`dat${x}`" @click="selectType('date',$moment().add(x,'days').format('YYYY-MM-DD'))">{{ $moment().add(x,'days').format('dddd')}}</li>
              </ul>
            </modal>
            
            <div class="desktop-section-feeds" v-if="!load">
              <template v-if="type == 'feeds'">
                <the-market v-if="(markets.markets != undefined)" :marketArr="markets" :index="marketId" />
                <the-match  v-for="(game,index) in games" :key="index" :market="markets" :game="game" :id="marketId" />
                <intersect sentinal-name="fetch-more" @on-intersection-element="onIntersectionElement"/>
              </template>
              <template v-else>
                <countries :sport_id="markets.sportId" @changeType="changeType"></countries>
              </template>
              <div class="text-center pd-top" v-if="!load && games.length == 0">
                <Alerts type="alert-info" :message=" $t('feeds.feeds-notice') " />
              </div>
            </div>
            <div v-else>
              <Loader />
            </div>
          </div>
        </div>
        <the-betslip class="hidden-sm hidden-xs home-bet"/>
      </div>
    </div>
  </div>
</template>
<script>
  import countries from './../sections/feeds/countries.vue';
  import intersect from './../sections/feeds/intersect.vue';
  import { mapGetters } from "vuex";
  import modal from './../partials/modal.vue';
  import BetslipService from "@/services/betslip";
  import Alerts from "@/components/sections/betslip-sections/alert";
  import Loader from "@/components/sections/loader.vue";

  export default {
    components: {
      countries,
      modal,
      Alerts,
      intersect,
      Loader
    },
    data() {
      return {
        type:'feeds',
        selectedMarket: 0,
        publicPath: process.env.BASE_URL,
        locale: this.$i18n.locale,
        sport_check: 'featured',
        time:null,
        load: false,
        options: {
          texts: {
            count:''
          }
        },
        show:false,
        showType:'',
        gameType:'',
      }
    },
    computed:{
      ...mapGetters({
          games: 'feeds',
          meta: 'meta',
          markets: 'markets',
          slides:'slides'
      }),
      marketId() {     
        return ( this.selectedMarket) ? this.selectedMarket : this.markets.displayId;
      }
    },  
    mounted() {
      if (this.$route.name === 'sport') 
      {
          this.getUpcomingGames(this.$route.params.id);
      } 
      else if (this.$route.name === 'league') 
      {
          this.getLeagueGames(this.$route.params.id,this.$route.params.sport);
      }
      else 
      {
          this.getOnMounted();
      }         

      BetslipService.setSubjectBetslip();          
    },
    watch: {
      time: async function(date) {
        if (date) {
          this.getGamesBydate(date);
        }
      },
      '$route': function() {          
        if (this.$route.name === 'sport') {
            this.getUpcomingGames(this.$route.params.id)
        } else if (this.$route.name === 'league') {
            this.getLeagueGames(this.$route.params.id,this.$route.params.sport)
        } else {
            this.getFeaturedGames()
        }
      }
    },
    methods: {
      changeType: function(arg) {
        this.type = arg;
        this.sport_check = 'countries';
      },
      getOnMounted: async function () {
        this.$Progress.start();
        this.load = true;
        this.$store.dispatch("get_markets",{
          id:10
        });

        await this.$store.dispatch("fetch_featured_games",{
          id:10,
          post:{
            "page":0,
            "size":15
          },
          type:'new'
        })
        this.gameType = 'featured'
        this.load = false
        this.$Progress.finish()
      },
      getTodayGames: async function(id) {
        this.load = true;
        await this.$store.dispatch("fetch_today_games",{
            id:id,
            post:{
              "page":0,
              "size":15
            },
            type:'new'
        })
        if( this.markets.sportId != id)  {
            this.$store.dispatch("get_markets",{
                id:id
            })
        }
        this.sport_check = 'today'
        this.type = 'feeds';
        this.load = false;
        this.gameType = 'today'
      },
      getGamesBydate: async function(date) {
        this.load = true;
        
        await this.$store.dispatch("fetch_games_by_date",{
            sportid:this.markets.sportId,
            to: date,
            post:{
              "page":0,
              "size":15
            },
            type:'new'
        })
        this.$store.dispatch("get_markets",{
            id:this.markets.sportId
        }) 

        // this.$refs.datepicker.closePopup()
        this.sport_check = 'upcoming'
        this.load = false;
        this.gameType = 'byDate'
      },
      getFeaturedGames: function() {
          
        this.$store.dispatch("set_featured_games")
        this.sport_check = 'featured'
        
        this.$store.dispatch("get_markets",{
            id:10,
        })
        this.type = 'feeds';
        this.gameType = 'featured'
      },
      getHighlightsGames: function () {
        this.$store.dispatch("set_highlights_games")
        this.sport_check = 'highlights'
      },
      getUpcomingGames: async function(id) {
        this.load = true;
        this.gameType = 'upcoming'
        this.sport_check = 'upcoming';
        await this.$store.dispatch("fetch_upcoming_games",{
            id:id,
            post:{
              "page":0,
              "size":15
            },
            type:'new'
        })
        if( this.markets.sportId != id)  {
            this.$store.dispatch("get_markets",{
                id:id
            })
        } 
        this.market = this.markets.displayId;
        
        this.type = 'feeds';
        this.selectedMarket = this.markets.displayId;
        this.load = false;
        
      },
      getLeagueGames: async function(id,sport) {
        if (this.markets.sportId != sport) {
            this.$store.dispatch("get_markets",{
                id:sport
            }) 
        } 
        this.load = true;
        
        await this.$store.dispatch("fetch_tournament_games",{
            id:id,
            post:{
              "page":0,
              "size":15
            },
            type:'new'
        })  
        this.sport_check = 'upcoming'
        this.load = false;
        this.gameType = 'league'
      },
      getNext: async function() {
        if(this.games.length > 14){
          switch (this.gameType) {
            case 'today':
              this.sport_check = 'today'
              await this.$store.dispatch("fetch_today_games",{
                id: this.meta.id,
                post:{
                  "page":this.meta.page,
                  "size":15
                },
                type:'paged'
              })
              break;
            case 'byDate':
              this.sport_check = 'upcoming'
              await this.$store.dispatch("fetch_games_by_date",{
                sportid:this.meta.id,
                to: this.meta.date,
                post:{
                  "page":this.meta.page,
                  "size":15
                },
                type:'paged'
              })
              break;
            case 'featured':
            this.sport_check = 'featured'
              await this.$store.dispatch("fetch_featured_games",{
                id:10,
                post:{
                  "page": this.meta.page,
                  "size":15
                },
                type:'paged'
              })
              break;
            case 'upcoming':
              this.sport_check = 'upcoming'
              await this.$store.dispatch("fetch_upcoming_games",{
                id:this.meta.id,
                post:{
                  "page":this.meta.page,
                  "size":15
                },
                type:'paged'
              })
              break;
            case 'league':
              this.sport_check = 'upcoming'
              await this.$store.dispatch("fetch_tournament_games",{
                id:this.meta.id,
                post:{
                  "page":this.meta.page,
                  "size":15
                },
                type:'paged'
              })  
              break;
            default:
              break;
          }
        }
      },
      toggleModal: function() {
        this.show = !this.show;
        this.showType = 'markets';
      },
      selectMarket: function(id) {
        this.selectedMarket = id;
        this.show = false;
      },
      clearMarket: function() {
        this.selectedMarket = this.markets.displayId;
      },
      clearSport: function() {
        this.getUpcomingGames(10);
      },
      toggleFilters: function() {
        this.show = !this.show;
        this.showType = 'filters';
      },
      selectType: function(type,arg) {
        switch (type) {
          case 'today':
            this.getTodayGames(arg)
            break;
          case 'date':
            this.getGamesBydate(arg);
            break;
          default:
            this.getFeaturedGames();
            break;
        }
        this.show = !this.show;
        this.showType = '';
      },
      onIntersectionElement:function(value) {
        // value will be true when the component is visible on the page otherwise false
        // you can do whatever you want
        if(value) {
          let count = (this.meta) ? Number(this.meta.current_page) : 0;
          this.getNext(count + 1);
        }
      },
    },
    created () {
      this.subscription = BetslipService.getAddedBetslip().subscribe( () => {
          this.$store.dispatch("update_games_state");
      })

      this.mysubscription = BetslipService.getRemovedBetslip().subscribe( () => { 
          this.$store.dispatch("remove_games_state");
      })
    },
    beforeDestroy () {
      // unsubscribe to ensure no memory leaks
      this.subscription.unsubscribe()
      this.mysubscription.unsubscribe()
    }
  }
</script>
<style scoped>
  .div-flex {
    display: flex;
  }
  .div-flex .feed {
    width: 100%;
  }
  .quick-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
  }
  .quick-filters li,.quick-filters span {
    background-color: var(--bg-dark-medium);
    border-radius: 15px;
    padding: 4px 10px;
    margin: 0px 2px;
    font-size: 12px;
    
  }
  .quick-filters.btom li {
    /* font-weight: bold; */
    text-transform: capitalize;
  }
  .quick-filters.btom li i {
    cursor: pointer;
  }
  .quick-filters li i {
    margin-right: 0px;
  }
  .pd-top {
    padding-top: 30px;
  }
</style>